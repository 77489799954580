import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"




// import withLocation from "../components/withLocation"
import { graphql } from 'gatsby'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import logo from "../images/android.png"
import appleIcon from "../images/app-store-icon.png"
import "../styles/global.scss"

// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//       <Image />
//     </div>
//     <Link to="/page-2/">Go to page 2</Link>
//   </Layout>
// )

// export default IndexPage

const nonNumber = {
  display: 'block'
};

function getUrlVars() {
  // Wrap the require in check for window
  if (typeof window !== `undefined`) {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  } else {
    return "";
  }
}


function getUrlParam(parameter, defaultvalue) {
  if (typeof window !== `undefined`) {
    var urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = getUrlVars()[parameter];
    }
    return urlparameter;
  } else {
    return defaultvalue;
  }
}


// const indexPage = ({ search, intl }) => {

//   // const { no, pin, theapp } = search

//   let linkStr = `${getUrlParam('theapp', '')}://pay/addmycard?no=${getUrlParam('no', '')}&pin=${getUrlParam('pin', '')}`

//   console.log("The link is " + linkStr);
//   // kyuramen://pay/addmycard?no=123&pin=456

// return (
//   <Layout>
//     <SEO title={intl.formatMessage({ id: "title" })} />
//     {/* <FormattedMessage id="hello" /> */}
//     <ul>
//       <li>Make sure app is downloaded on your phone</li>
//       <li>Click on below link to add the gift card</li>
//     </ul>
//     <a href={linkStr}>Add to Jupioca App</a>
//   </Layout>
// )
// }

// export default withLocation(indexPage)

class IndexPage extends React.Component {

  state = {
    linkStr: ''
  };


  // componentDidMount() {
  //   let linkStrTmp = `${getUrlParam('app', '')}://pay/addmycard?no=${getUrlParam('no', '')}&pin=${getUrlParam('pin', '')}`

  //   this.setState({
  //     linkStr: linkStrTmp
  //   })
  // }

  componentDidMount() {
    let linkStrTmp = `${getUrlParam('app', '')}://pay/addmycard?no=${getUrlParam('no', '')}&pin=${getUrlParam('pin', '')}`

    this.setState({
      linkStr: linkStrTmp
    })
  }

  render() {
    const { data } = this.props;
    // const { data } = this.props;
    return (
      <Layout>
        <SEO title={this.props.intl.formatMessage({ id: "title" })} />
        {/* <FormattedMessage id="hello" /> */}
        <h3>
        <FormattedMessage id="claimGift" />
        {/* <FormattedMessage id="welcome" /> */}
        </h3>
        <ol>
          <li>Make sure {data.site.siteMetadata.appName} app is downloaded on your phone, click below to download if needed.
            <br />
            <br />
            <a href={data.site.siteMetadata.iosLink}>
              <img src={appleIcon} alt="Logo" width="135" />
            </a>

            <br />
            <br />

      {data.site.siteMetadata.androidLink.length > 0 && 
      
      <a href={data.site.siteMetadata.androidLink}>
      <img src={logo} alt="Logo" width="135" />
    </a>
      }

          </li>
          <li>
            Click on below button on your phone to add the gift card to {data.site.siteMetadata.appName} app.
            <br />
            <br />
          </li>
          <li style={nonNumber}>
            <a href={this.state.linkStr} className="button1">Save to {data.site.siteMetadata.appName} App</a>
          </li>
        </ol>
      </Layout>
    )
  }


}

export default injectIntl(IndexPage);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        appName
        androidLink
        iosLink
      }
    }
  }
`